import {ReadGlonasSerialNumber, WriteGlonasSerialNumber} from "../Commands";
import CommonTextControl from "./CommonTextControl";

const GlonasNumberControl = ({executeCustomCommand, onValueChanged}) => {
  const getSerialNumberCommand = async () => {
    const result = await executeCustomCommand(ReadGlonasSerialNumber, [])

    const utf8decoder = new TextDecoder()
    let buffer = new Uint8Array(result.Params);

    return utf8decoder.decode(buffer);
  }

  const setSerialNumberCommand = async (bytes) => {
    await executeCustomCommand(WriteGlonasSerialNumber, bytes)
  }

  return <CommonTextControl header="Номер глонасс приемника"
                            onValueChanged={onValueChanged}
                            getTextCommand={getSerialNumberCommand}
                            setTextCommand={setSerialNumberCommand}
  />
}

export default GlonasNumberControl;