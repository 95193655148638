import {ReadMehTehDate, WriteMehTehDate} from "../../Commands";
import {Fragment, useState} from "react";
import AdminComponent from "../AdminComponent";

const ClockMehTehDateControl = ({executeCommand}) => {
  const [loading, SetLoading] = useState(false);
  const [MaintenanceCount, SetMaintenanceCount] = useState(undefined);
  const [Day, SetDay] = useState(undefined);
  const [Mount, SetMount] = useState(undefined);
  const [year, SetYear] = useState(undefined);
  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(WriteMehTehDate, [
        Number(MaintenanceCount),
        Number(Day),
        Number(Mount),
        Number(year)
      ])
    } finally {
      SetLoading(false);
    }
  }

  const readCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ReadMehTehDate, [])
      const timeData = result.Params.slice(2);

      const maintenanceCount = Number(timeData[0]);
      const day = Number(timeData[1]);
      const mount = Number(timeData[2]);
      const year = Number(timeData[3]);

      SetMaintenanceCount(maintenanceCount)
      SetDay(day)
      SetMount(mount)
      SetYear(year)
    } finally {
      SetLoading(false);
    }
  }

  return (
    <Fragment>
      <p>КОЛИЧЕСТВО ТО И ДАТЫ ТО</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="mehMaintenanceCount" className="form-label">Количество ТО</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="mehMaintenanceCount"
                     min="0"
                     max="23"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       MaintenanceCount(Number(event.target.value))
                     }}
                     value={MaintenanceCount}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockDay" className="form-label">День</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockDay"
                     min="0"
                     max="23"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetDay(Number(event.target.value))
                     }}
                     value={Day}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockMount" className="form-label">Месяц</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockMount"
                     min="0"
                     max="59"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetMount(Number(event.target.value))
                     }}
                     value={Mount}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockYear" className="form-label">Год</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockYear"
                     min="0"
                     max="99"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetYear(Number(event.target.value))
                     }}
                     value={year}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-outline-secondary" type="button" onClick={readCommand} disabled={loading}>
                Получить
              </button>
              <AdminComponent>
                {() => (
                  <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
                    {/*{spin}*/}
                    Установить
                  </button>
                )}
              </AdminComponent>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default ClockMehTehDateControl;