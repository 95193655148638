import './StatusControl.scss'

const StatusControl = ({status}) => {
  const cssStatus = !!status ? `bubble-${status}` : ""

  return (
    <div className="status-container">
      <div className={'bubble' + ` ${cssStatus}`}>
      <span className="bubble-outer-dot">
        <span className="bubble-inner-dot"/>
      </span>
      </div>
    </div>
  )
}

export default StatusControl;