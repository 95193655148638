import {GetVersion} from "../Commands";
import CommonTextControl from "./CommonTextControl";
import {Esp} from "../Targets";

const EspVersionControl = ({executeCommand}) => {
  const getSerialNumberCommand = async () => {
    const result = await executeCommand(Esp, GetVersion, [])
    return result.ESPVersion;
  }

  return <CommonTextControl header="Версия прошивки"
                            getTextCommand={getSerialNumberCommand}
  />
}

export default EspVersionControl;