import {Fragment, useEffect, useState} from "react";
import {ReadParameters, WriteDateTimeCommand} from "../Commands";

const DateTimeControl = ({executeCustomCommand}) => {
  const timePositionInParams = 15;

  const [loading, SetLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [hours, setHours] = useState(undefined);
  const [minutes, setMinutes] = useState(undefined);
  const [seconds, setSeconds] = useState(undefined);
  const [DayOfWeek, SetDayOfWeek] = useState(undefined);
  const [Day, SetDay] = useState(undefined);
  const [Mount, SetMount] = useState(undefined);
  const [year, SetYear] = useState(undefined);
  const week = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds < 59) {
          setSeconds(seconds + 1);
        } else {
          setSeconds(0);
          if (minutes < 59) {
            setMinutes(minutes + 1)
          } else {
            setMinutes(0)
            if (hours < 23) {
              setHours(hours + 1)
            } else {
              setHours(0);
            }
          }
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, hours, minutes, seconds]);


  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCustomCommand(WriteDateTimeCommand, [
        parseInt(hours, 16),
        parseInt(minutes, 16),
        parseInt(seconds, 16),
        DayOfWeek,
        parseInt(Day, 16),
        parseInt(Mount, 16),
        parseInt(year, 16)])
    } finally {
      SetLoading(false);
    }
  }

  const readCommand = async (e) => {
    e.preventDefault();

    //6. Шесть байт текущего времени и даты первичных часов ЧС. Байты идут по порядку часы, минуты, секунды, число, месяц, год (последние две цифры). Все значения в BCD формате т.е. 12 записывается как 0х12. 

    SetLoading(true);
    setIsActive(false)

    try {
      const result = await executeCustomCommand(ReadParameters, [])
      const timeData = result.Params.slice(timePositionInParams, timePositionInParams + 6);

      const hours = Number(timeData[0].toString(16));
      const minutes = Number(timeData[1].toString(16));
      const seconds = Number(timeData[2].toString(16));
      const day = Number(timeData[3].toString(16));
      const mount = Number(timeData[4].toString(16));
      const year = Number(timeData[5].toString(16));

      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
      SetDay(day)
      SetMount(mount)
      SetYear(year)

      setIsActive(true)
    } finally {
      SetLoading(false);
    }
  }

  const enableTimer = () => {
    setIsActive(true)
  }
  const disableTimer = () => {
    setIsActive(false)
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <p>Установка ВРЕМЕНИ СУТОК ПЕРВИЧНЫХ ЧАСОВ</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockHour" className="form-label">Часы</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockHour"
                     min="0"
                     max="23"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       setHours(Number(event.target.value))
                     }}
                     value={hours}
                     onFocus={disableTimer}
                     onBlur={enableTimer}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockMinute" className="form-label">Минуты</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockMinute"
                     min="0"
                     max="59"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       setMinutes(Number(event.target.value))
                     }}
                     value={minutes}
                     onFocus={disableTimer}
                     onBlur={enableTimer}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockSeconds" className="form-label">Секунды</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockSeconds"
                     min="0"
                     max="59"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       setSeconds(Number(event.target.value))
                     }}
                     value={seconds}
                     onFocus={disableTimer}
                     onBlur={enableTimer}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockChannel" className="form-label">День недели</label>
              </div>
              <select className="form-control" id="clockChannel" required={true}
                      onChange={event => {
                        event.preventDefault();
                        SetDayOfWeek(Number(event.target.value))
                      }}
                      value={DayOfWeek}>
                {week.map((x, index) => <option key={x} value={index + 1}>{x}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockDay" className="form-label">День</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockDay"
                     min="0"
                     max="23"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetDay(Number(event.target.value))
                     }}
                     value={Day}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockMount" className="form-label">Месяц</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockMount"
                     min="0"
                     max="59"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetMount(Number(event.target.value))
                     }}
                     value={Mount}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockYear" className="form-label">Год</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockYear"
                     min="0"
                     max="99"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetYear(Number(event.target.value))
                     }}
                     value={year}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-outline-secondary" type="button" onClick={readCommand} disabled={loading}>
                Получить
              </button>
              <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
                {spin}
                Установить
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default DateTimeControl;