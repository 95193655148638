import {WriteDeviceSerial} from "../Commands";
import CommonTextControl from "./CommonTextControl";
import {NumberToByteArray} from "../Converters";

const DimmerSerialControl = ({executeCommand}) => {
  const setSerialNumberCommand = async (value) => {
    const bytes = NumberToByteArray(value, 2)
    await executeCommand(WriteDeviceSerial, [bytes])
  }

  return <CommonTextControl header="Серийный номер диммера"
                            type={"number"}
                            setTextCommand={setSerialNumberCommand}
  />
}

export default DimmerSerialControl;