import {Fragment, useState} from "react";
import {Reboot, UpdateToLatest} from "../Commands";
import {Esp} from "../Targets";

const UpdateControl = ({executeCommand}) => {

  const [loading, SetLoading] = useState(false);

  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(Esp, UpdateToLatest, [])
    } finally {
      SetLoading(false);
    }
  }

  const rebootDeviceCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(Esp, Reboot, [])
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
            {spin}
            Обновить
          </button>
          <button className="btn btn-warning" type="button" onClick={rebootDeviceCommand} disabled={loading}>
            {spin}
            Перезагрузить
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default UpdateControl;