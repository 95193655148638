import React, {Fragment, useEffect, useState} from "react";
import AdminComponent from "./AdminComponent";

const CommonTextControl = (
  {
    header,
    getTextCommand,
    setTextCommand,
    onValueChanged,
    initialValue,
    label,
    maxLength = null,
    type = "text"
  }) => {
  const [loading, SetLoading] = useState(false);
  const [inputText, SetInputText] = useState("");

  useEffect(() => {
    if (!initialValue)
      return;

    SetInputText(initialValue)
  }, [initialValue])


  const getInputTextCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await getTextCommand();
      updateInputText(result)
    } finally {
      SetLoading(false);
    }
  }

  const setInputTextCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      if (type === "number") {
        await setTextCommand(Number(inputText))
      } else {
        const utf8Encode = new TextEncoder();
        const bytes = utf8Encode.encode(inputText + '\n');

        await setTextCommand([...bytes])
      }
    } finally {
      SetLoading(false);
    }
  }

  const updateInputText = (text) => {
    SetInputText(text)

    if (typeof (onValueChanged) === 'function') {
      onValueChanged(text)
    }
  }


  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <p>{header}</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              {
                !!label
                  ?
                  (
                    <div className="input-group-text">
                      <label className="form-label">{label}</label>
                    </div>
                  )
                  : null
              }
              <input className="form-control"
                     type={type}
                     maxLength={maxLength}
                     onChange={event => {
                       updateInputText(event.target.value)
                     }}
                     value={inputText}
              />
              <AdminComponent>
                {
                  () => (
                    <Fragment>
                      {
                        typeof (setTextCommand) === 'function' ?
                          <button className="btn btn-primary" type="button" onClick={setInputTextCommand} disabled={loading}>
                            {spin}
                            Записать
                          </button>
                          : null
                      }
                    </Fragment>
                  )
                }
              </AdminComponent>
              {
                typeof (getTextCommand) === 'function' ?
                  <button className="btn btn-outline-secondary" type="button" onClick={getInputTextCommand} disabled={loading}>
                    {spin}
                    Получить
                  </button>
                  : null
              }
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default CommonTextControl;