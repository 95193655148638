import {ReadMehanicsMeteo, ReadMehRepairDate, WriteMehRepairDate} from "../../Commands";
import {Fragment, useState} from "react";
import {ByteArrayToNumber} from "../../Converters";

const ClockMehMeteoComponent = ({executeCommand}) => {
  const [loading, SetLoading] = useState(false);
  const [weatherData, setWeatherData] = useState(null);

  const readCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ReadMehanicsMeteo, [])
      const localWeatherData = result.Params.slice(2);

      if (localWeatherData.length === 0)
        return
      // const localWeatherData = [0, 256, 29, 139, 1, 62]

      const weather = {
        temperature: ByteArrayToNumber(localWeatherData.slice(0, 2)) / 10,
        pressure: ByteArrayToNumber(localWeatherData.slice(2, 4)) / 10,
        humidity: ByteArrayToNumber(localWeatherData.slice(4, 6)) / 10
      };

      setWeatherData(weather)

    } finally {
      SetLoading(false);
    }
  }

  return (
    <Fragment>
      <p>ЗАПРОС МЕТЕОДАННЫХ</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="mehTemp" className="form-label">Температура</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="mehTemp"
                     required={true}
                     value={weatherData?.temperature}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="mehPressure" className="form-label">Давление</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="mehPressure"
                     required={true}
                     value={weatherData?.pressure}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="mehHumidity" className="form-label">Влажность</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="mehHumidity"
                     required={true}
                     value={weatherData?.humidity}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-outline-secondary" type="button" onClick={readCommand} disabled={loading}>
                Получить
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default ClockMehMeteoComponent;