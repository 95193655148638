const NumberToBitArray = (value: number): Array<boolean> => {

  const arr = [];

  for (let i = 7; i >= 0; i--) {
    const bit = !!(value & (1 << i));
    arr.push(bit)
  }

  return arr;
}

const BitArrayToNumber = (value: Array<boolean>): number => {
  return value.reduce((res, x) => res << 1 | x);
}

const ByteArrayToNumber = (bytes: Array<number>): number => {
  let number = 0;

  for (let i = 0; i < bytes.length; i++) {
    number |= bytes[bytes.length - i - 1] << (8 * i);
  }

  return number;
}

const NumberToByteArray = (number: number, count: number): Array<number> => {
  const bytes = [];

  for (let i = count - 1; i >= 0; i--) {
    bytes.push((number >> (8 * i)) & 0xFF);
  }

  return bytes;
}


export {NumberToBitArray, BitArrayToNumber, ByteArrayToNumber, NumberToByteArray};