import React from 'react'
import {Component} from 'react'
import {Navigate} from 'react-router-dom'
import {ApplicationPaths, QueryParameterNames} from '../services/ApiAuthorizationConstants'
import authService from '../services/AuthorizeService'

export default class AdminRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      isAdmin: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const {ready, isAdmin} = this.state;
    const link = document.createElement("a");
    link.href = this.props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else {
      const {element} = this.props;
      return isAdmin ? element : <Navigate replace to={redirectUrl}/>;
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    const user = await authService.getUser();

    const isAdmin = authenticated && user && user.isAdmin
    this.setState({ready: true, isAdmin});
  }

  async authenticationChanged() {
    this.setState({ready: false, isAdmin: false});
    await this.populateAuthenticationState();
  }
}
