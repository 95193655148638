import {useEffect, useState} from "react";
import './TimeInput.scss'

const TimeInput = ({hours, minutes, onTimeChanged, maxHoursValue = 23}) => {
  const [localHours, SetLocalHours] = useState("");
  const [localMinutes, SetLocalMinutes] = useState("");
  const regex = new RegExp(/^\d*$/, 'm');

  useEffect(() => {
    SetLocalHours(Number(hours) < 10 ? `0${Number(hours)}` : `${hours}`);
    SetLocalMinutes(Number(minutes) < 10 ? `0${Number(minutes)}` : `${minutes}`);
  }, [hours, minutes])

  const onChangeHours = (event) => {
    if (!regex.test(event.target.value))
      return;

    const value = Number(event.target.value)

    if (value >= 0 && value <= maxHoursValue) {
      SetLocalHours(event.target.value);
    }
  };

  const onChangeMinutes = (event) => {
    if (!regex.test(event.target.value))
      return;

    const value = Number(event.target.value)

    if (value >= 0 && value <= 59) {
      SetLocalMinutes(value);
    }
  };

  const onBlur = (event) => {
    if (Number(localHours) < 10) {
      SetLocalHours(`0${Number(localHours)}`)
    }

    if (Number(localMinutes) < 10) {
      SetLocalMinutes(`0${Number(localMinutes)}`)
    }

    if (typeof (onTimeChanged) === 'function') {
      onTimeChanged({hours: localHours, minutes: localMinutes})
    }
  };

  return (
    <div className="time-input-group">
      <input type="text" className="time-input" onChange={onChangeHours} onBlur={onBlur} value={localHours}/>
      <span>:</span>
      <input type="text" className="time-input" onChange={onChangeMinutes} onBlur={onBlur} value={localMinutes}/>
    </div>
  );
};


export default TimeInput;