import ApiAuthorzationRoutes from './components/authorization/ApiAuthorizationRoutes';
import {Home} from "./pages/Home";
import {Devices} from "./pages/Devices";
import Device from "./pages/Device";
import UsersComponent from "./pages/UsersComponentjs";
import UserComponent from "./pages/UserComponent";
import LogPage from "./pages/LogPage";

const AppRoutes = [
  {
    index: true,
    element: <Home/>
  },
  {
    path: '/devices',
    requireAuth: true,
    element: <Devices/>
  },
  ...ApiAuthorzationRoutes,
  {
    path: '/devices/:deviceId',
    requireAuth: true,
    element: <Device/>
  },
  {
    path: '/devices/:deviceId/logs',
    requireAuth: true,
    element: <LogPage/>
  },
  {
    path: '/users',
    requireAdmin: true,
    element: <UsersComponent/>
  },
  {
    path: '/users/:userId',
    requireAdmin: true,
    element: <UserComponent/>
  }
];

export default AppRoutes;
