import React, {Fragment, useEffect, useState} from "react";
import './IpInput.scss'

const IpInput = ({labelName, octets, onValueChanged}) => {
  const [blocks, setBlocks] = useState({
    "block-1": "",
    "block-2": "",
    "block-3": "",
    "block-4": "",
  });
  const [blocksList, setBlocksList] = useState([1, 2, 3, 4]);
  const [focusedInput, setFocusedInput] = useState(1);

  useEffect(() => {
    if (!!octets && octets.length === 4) {
      setBlocks({
        "block-1": octets[0],
        "block-2": octets[1],
        "block-3": octets[2],
        "block-4": octets[3],
      });
    }
  }, [octets]);

  const handleChange = (event) => {
    if (
      parseInt(event.target.value.split("")[0]) === 0 &&
      event.target.value.length > 1
    ) {
      return;
    } else if (
      (event.target.value >= 0 && event.target.value < 256) ||
      event.target.value === ""
    ) {
      setBlocks({...blocks, [event.target.name]: event.target.value});
    }
  };

  const handleClick = (event) =>
    setFocusedInput(parseInt(event.target.name.split("-")[1]));

  const handleClear = () => {
    setBlocks({
      "block-1": "",
      "block-2": "",
      "block-3": "",
      "block-4": "",
    });
    setFocusedInput(1);
  };

  const handleKeyEvents = (event) => {
    if (event.charCode === 13 || event.charCode === 32) {
      if (focusedInput < 4) {
        setFocusedInput(focusedInput + 1);
      } else {
        setFocusedInput(1);
      }
    }
  };

  const handleBlur = () => {
    const blockArr = [];

    if (blocks[`block-${focusedInput}`]?.length === 3 && focusedInput < 4) {
      setFocusedInput(focusedInput + 1);
    }

    Object.keys(blocks).map((key) => {
      blockArr.push(blocks[key]);
    });
    if (blockArr.length > 0) {
      if (typeof (onValueChanged) === "function")
        onValueChanged({
          octets: blockArr.map(x => Number(x)),
          ip: blockArr.join(".")
        })
    }
  }

  return (
    <Fragment>
      <div className={"input-group mb-3"}>
        {
          labelName === "" || labelName === undefined
            ? null
            : (
              <div className="input-group-text">
                <label className="form-label">{labelName}</label>
              </div>
            )
        }
        <div className={"form-control"}>
          <div className="ip-wrapper">
            {
              blocksList.map((x, i) => (
                <Fragment>
                  <input
                    onKeyDown={handleKeyEvents}
                    maxLength={3}
                    type="number"
                    className="ip-input"
                    name={`block-${i + 1}`}
                    id={`block-${i + 1}`}
                    onChange={handleChange}
                    onClick={handleClick}
                    onBlur={handleBlur}
                    value={blocks[`block-${i + 1}`]}
                  />
                  {
                    i !== 3
                      ? <p className={"ip-dot"}>.</p>
                      : null
                  }
                </Fragment>
              ))
            }
            <button className="btn btn-light ip-btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default IpInput;