import React, {Component, Fragment, useEffect, useState} from 'react';
import authService from '../../services/AuthorizeService';
import {NavItem, NavLink} from "reactstrap";
import {Link} from "react-router-dom";

const UsersMenu = () => {
  const [isAdmin, SetIsAdmin] = useState(false);

  useEffect(() => {
    const subscription = authService.subscribe(() => populateState());
    populateState()

    return () => {
      authService.unsubscribe(subscription);
    };
  }, []);

  const populateState = async () => {
    const [user] = await Promise.all([authService.getUser()])
    SetIsAdmin(user && user.isAdmin)
  }

  const render = () => {
    if (isAdmin) {
      return (
        <Fragment>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={"/users"}>Пользователи</NavLink>
          </NavItem>
        </Fragment>
      );
    }
    return (
      <Fragment>
      </Fragment>
    )
  }

  return render()
}

export default UsersMenu;