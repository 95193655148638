import React, {useEffect, useState} from 'react';
import authService from "../services/AuthorizeService";
import {Link} from "react-router-dom";

const UsersComponent = ({}) => {
  const [loading, SetLoading] = useState(false);
  const [users, SetUsers] = useState([]);
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [error, SetError] = useState("");
  const [success, SetSuccess] = useState("");

  useEffect(() => {
    populateUsersData();
  }, []);

  const populateUsersData = async () => {
    SetLoading(true)

    const response = await fetch('/api/users', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      }
    });

    if (!response.ok) {
      SetLoading(false)
      return;
    }

    const data = await response.json();
    SetUsers(data.users)
    SetLoading(false)
  }

  const addUser = async (email, phone) => {

    SetError("")
    SetSuccess("")

    const requestData = {
      email: email,
      phone: phone
    };

    const body = JSON.stringify(requestData);

    try {
      const response = await fetch(`/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
        },
        body: body
      });

      if (response.ok) {
        await populateUsersData()
        SetSuccess("Пользователь успешно создан")
      } else if (response.status === 400) {
        const errorResponse = await response.json();
        let errors = [];
        for (let key in errorResponse.errors) {
          errors.push(errorResponse.errors[key]);
        }
        SetError(`Ошибка создания пользователя: ${errors.join(',')}`)
      }
    } catch (e) {
      console.log(e)
    }
  };

  const renderAlert = () => {
    if (error === "")
      return

    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    )
  }

  const renderSuccess = () => {
    if (success === "")
      return

    return (
      <div className="alert alert-success" role="alert">
        {success}
      </div>
    )
  }


  const renderTable = () => {
    return (
      <div>
        <table className="table">
          <thead>
          <tr>
            <td>Логин</td>
          </tr>
          </thead>
          <tbody>
          {
            users.map((user, index) => (
              <tr key={user.id}>
                <td><Link to={`/users/${user.id}`}>
                  {user.login}
                </Link>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
        <div className={"row"}>
          <div className="col-5">
            <div className={"input-group"}>
              <div className="input-group-text">Email</div>
              <input type={"email"}
                     id={"newUserEmail"}
                     className={"form-control"}
                     value={email}
                     onChange={(event) => {
                       event.preventDefault();
                       SetEmail(event.target.value)
                     }}
              />
            </div>
          </div>
          <div className="col-5">
            <div className={"input-group"}>
              <div className="input-group-text">Телефон</div>
              <input type={"tel"}
                     id={"newUserPhone"}
                     className={"form-control"}
                     value={phone}
                     onChange={(event) => {
                       event.preventDefault();
                       SetPhone(event.target.value)
                     }}
              />
            </div>
          </div>
          <div className={"col-2"}>
            <div className={"input-group"}>
              <button className={"btn btn-primary"}
                      onClick={async () => {
                        await addUser(email, phone)
                      }}>
                Добавить
              </button>
            </div>
          </div>
        </div>
        <div className={"row"}>
          {renderAlert()}
          {renderSuccess()}
        </div>
      </div>
    );
  }

  const render = () => {
    return renderTable()
  }

  return render()
}

export default UsersComponent;