import React, {Fragment, useEffect, useState} from "react";
import ToggleSwitch from "./Inputs/ToggleSwitch";
import TimeInChannelControl from "./TimeInChannelControl";
import AdminComponent from "./AdminComponent";

const ChannelControl = ({channel, hours, minutes, impulse, enabled, sync, onChannelChanged}) => {

  const [localHours, SetLocalHours] = useState(0);
  const [localMinutes, SetLocalMinutes] = useState(0);
  const [localImpulse, SetLocalImpulse] = useState(0);
  const [localEnabled, SetLocalEnabled] = useState(false);
  const [localSync, SetLocalSync] = useState(false);

  useEffect(() => {
    SetLocalImpulse(impulse)
    SetLocalHours(hours)
    SetLocalMinutes(minutes)
    SetLocalEnabled(enabled)
    SetLocalSync(sync)
  }, [hours, minutes, impulse, enabled, sync])

  return (
    <Fragment>
      <fieldset>
        <div className="row">
          <div className={"col-3"}>
            <TimeInChannelControl channel={channel}
                                  hours={localHours}
                                  minutes={localMinutes}
                                  onTimeChanged={(channel, hours, minutes) => {
                                    SetLocalHours(hours)
                                    SetLocalMinutes(minutes)
                                    onChannelChanged(channel, hours, minutes, localImpulse, localEnabled, localSync)
                                  }}/>
          </div>
          <div className={"col"}>
            <ToggleSwitch id={`channelEnabled${channel}`}
                          small={true}
                          name={""}
                          onChange={(state) => {
                            SetLocalEnabled(state)
                            onChannelChanged(channel, localHours, localMinutes, localImpulse, state, localSync)
                          }}
                          checked={localEnabled}
                          disabled={false}/>
            {
              localEnabled
                ? <span>Включен</span>
                : <span>Выключен</span>
            }
          </div>
          <div className={"col-3"}>
            <input className="form-control"
                   type="number"
                   id={`impulseChannel${channel}`}
                   min="2"
                   max="240"
                   required={true}
                   onChange={(e) => {
                     e.preventDefault()
                     const value = Number(e.target.value)
                     SetLocalImpulse(value)
                   }}
                   onBlur={(e) => {
                     onChannelChanged(channel, localHours, localMinutes, localImpulse, localEnabled, localSync)
                   }}
                   value={localImpulse}
            />
          </div>

          <div className={"col-3"}>
            <AdminComponent>
              {
                () => (
                  <Fragment>
                    <ToggleSwitch id={`channelSync${channel}`}
                                  small={true}
                                  name={""}
                                  onChange={(state) => {
                                    SetLocalSync(state)
                                    onChannelChanged(channel, localHours, localMinutes, localImpulse, localEnabled, state)
                                  }}
                                  checked={localSync}
                                  disabled={false}/>
                    {
                      localSync
                        ? <span>Включен</span>
                        : <span>Выключен</span>
                    }
                  </Fragment>
                )}
            </AdminComponent>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default ChannelControl;