import {Fragment, useEffect, useState} from "react";
import RelayControl from "./RelayControl";
import {ClockStation} from "../Targets";
import {ReadRelayMode, WriteRelayMode} from "../Commands";

const RelayListControl = ({executeCommand}) => {
  const [loading, SetLoading] = useState(false);
  const [initialized, SetInitialized] = useState(false);
  const [RelayModeChannelOne, SetRelayModeChannelOne] = useState(-1);
  const [RelayModeChannelTwo, SetRelayModeChannelTwo] = useState(-1);

  const writeRelayModeCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteRelayMode, [RelayModeChannelOne, RelayModeChannelTwo])
    } finally {
      SetLoading(false);
    }
  }

  const readRelayModeCommand = async () => {
    SetLoading(true);

    try {
      const relayStateResult = await executeCommand(ClockStation, ReadRelayMode, [])

      SetRelayModeChannelOne(relayStateResult.Params[0])
      SetRelayModeChannelTwo(relayStateResult.Params[1])

      SetInitialized(true)

    } catch (e) {
      console.error(e)
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <div className="input-group">
            <button className="btn btn-outline-secondary" type="button" onClick={readRelayModeCommand} disabled={loading}>
              {spin}
              Получить
            </button>
            <button className="btn btn-primary" type="button" onClick={writeRelayModeCommand} disabled={loading}>
              {spin}
              Установить
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <fieldset disabled={!initialized}>
            <RelayControl executeCommand={executeCommand}
                          channel={0}
                          relayMode={RelayModeChannelOne}
                          SetRelayMode={SetRelayModeChannelOne}/>
          </fieldset>
        </div>
        <div className="col">
          <fieldset disabled={!initialized}>
            <RelayControl executeCommand={executeCommand}
                          channel={1}
                          relayMode={RelayModeChannelTwo}
                          SetRelayMode={SetRelayModeChannelTwo}/>
          </fieldset>
        </div>

      </div>
    </Fragment>
  )
}

export default RelayListControl;