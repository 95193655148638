import CommonTextControl from "../CommonTextControl";
import {ReadMehModel, WriteMehModel} from "../../Commands";

const ClockMehModelComponent = ({executeCommand}) => {
  const getCommand = async () => {
    const result = await executeCommand(ReadMehModel, [])
    const decoder = new TextDecoder("windows-1251")
    console.log(result.Params)
    let buffer = new Uint8Array(result.Params.slice(2).slice(-16).filter(x => x !== 0));

    return decoder.decode(buffer);
  }

  const setCommand = async (bytes) => {
    await executeCommand(WriteMehModel, bytes)
  }

  return <CommonTextControl header="МОДЕЛЬ МЕХАНИЗМА"
                            getTextCommand={getCommand}
                            setTextCommand={setCommand}
  />
}

export default ClockMehModelComponent;