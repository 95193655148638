import {Fragment, useState} from "react";
import {
  ChimesTestCommand,
  MelodyTestCommand,
  ReadChimes,
  WriteChimes,
  WriteChimesTime,
  WriteChimesVolume
} from "../Commands";
import {ClockStation, Esp} from "../Targets";

const ChimesControl = ({executeCommand}) => {

  // 1. Один байт часа начала боя 1. Выражен в BCD формате. Значение от 0x00 до 0x23. 
  const startFirstChimesPosition = 0;
  // 2. Один байт часа конца боя 1. Выражен в BCD формате. Значение от 0x00 до 0x23. 
  const endFirstChimesPosition = 1;
  // 3. Один байт часа начала боя 2. Выражен в BCD формате. Значение от 0x00 до 0x23. 
  const startSecondChimesPosition = 2;
  // 4. Один байт часа конца боя 2. Выражен в BCD формате. Значение от 0x00 до 0x23. 
  const endSecondChimesPosition = 3;
  // 5. Один байт режима боя. Значения от 0 до 6. 0 – боя нет, 1 – только в 12, 2 – только в 12 и 18, 3 – каждый час, 4 – каждые полчаса, 5 – каждые 15 минут, 6 – только бой. 
  const chimesModePosition = 4;
  // 6. Один байт схема боя. Значения от 0 до 9. 
  const chimesSchemePosition = 5;
  // 7. Один байт признака «Новогодний бой». Если равен 0, то новогодний бой отключен. Если не равен 0 – новогодний бой включен. 
  const christmasChimesPosition = 6;
  // 8. Один байт уровня громкости. Значения от 0 до 30.
  const chimesSoundLevelPosition = 7;

  const [loading, SetLoading] = useState(false);
  const [FirstStartChimes, SetFirstStartChimes] = useState(0);
  const [FirstEndChimes, SetFirstEndChimes] = useState(0);
  const [SecondStartChimes, SetSecondStartChimes] = useState(0);
  const [SecondEndChimes, SetSecondEndChimes] = useState(0);
  const [ChimesMode, SetChimesMode] = useState(0);
  const [ChimesScheme, SetChimesScheme] = useState(0);
  const [ChristmasChimes, SetChristmasChimes] = useState(false);
  const [ChimesSoundLevel, SetChimesSoundLevel] = useState(0);

  const chimesModeList = [
    {value: 0, text: "боя нет"},
    {value: 1, text: "только в 12"},
    {value: 2, text: "только в 12 и 18"},
    {value: 3, text: "каждый час"},
    {value: 4, text: "каждые полчаса"},
    {value: 5, text: "каждые 15 минут"},
    {value: 6, text: "только бой"},
  ]


  const readCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ClockStation, ReadChimes, []);
      const params = result.Params;

      SetFirstStartChimes(Number(params[startFirstChimesPosition].toString(16)))
      SetFirstEndChimes(Number(params[endFirstChimesPosition].toString(16)))
      SetSecondStartChimes(Number(params[startSecondChimesPosition].toString(16)))
      SetSecondEndChimes(Number(params[endSecondChimesPosition].toString(16)))
      SetChimesMode(params[chimesModePosition])
      SetChimesScheme(params[chimesSchemePosition])
      SetChristmasChimes(!!params[christmasChimesPosition])
      SetChimesSoundLevel(params[chimesSoundLevelPosition])

    } finally {
      SetLoading(false);
    }
  }

  const writeChimesTimeCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteChimesTime, [
        parseInt(FirstStartChimes, 16),
        parseInt(FirstEndChimes, 16),
        parseInt(SecondStartChimes, 16),
        parseInt(SecondEndChimes, 16)
      ]);
    } finally {
      SetLoading(false);
    }
  }

  const writeChimesCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteChimes, [ChimesMode, ChimesScheme, Number(ChristmasChimes)]);
    } finally {
      SetLoading(false);
    }
  }

  const writeChimesVolumeCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteChimesVolume, [ChimesSoundLevel]);
    } finally {
      SetLoading(false);
    }
  }

  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, ChimesTestCommand, [])
    } finally {
      SetLoading(false);
    }
  }

  const melodyCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, MelodyTestCommand, [])
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <div className="row">
        <p>Бой 1</p>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3 col">
            <div className="input-group-text">
              <label htmlFor="startChimes1" className="form-label">Начало</label>
            </div>
            <input className="form-control"
                   type="number"
                   id="startChimes1"
                   min={0}
                   max={23}
                   onChange={event => {
                     event.preventDefault();
                     SetFirstStartChimes(Number(event.target.value))
                   }}
                   value={FirstStartChimes}
            />
          </div>
        </div>
        <div className="col">
          <div className="input-group mb-3 col">
            <div className="input-group-text">
              <label htmlFor="endChimes1" className="form-label">Конец</label>
            </div>
            <input className="form-control"
                   type="number"
                   id="endChimes1"
                   min={0}
                   max={23}
                   onChange={event => {
                     event.preventDefault();
                     SetFirstEndChimes(Number(event.target.value))
                   }}
                   value={FirstEndChimes}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <p>Бой 2</p>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3 col">
            <div className="input-group-text">
              <label htmlFor="startChimes1" className="form-label">Начало</label>
            </div>
            <input className="form-control"
                   type="number"
                   id="startChimes2"
                   min={0}
                   max={23}
                   onChange={event => {
                     event.preventDefault();
                     SetSecondStartChimes(Number(event.target.value))
                   }}
                   value={SecondStartChimes}
            />
          </div>
        </div>
        <div className="col">
          <div className="input-group mb-3 col">
            <div className="input-group-text">
              <label htmlFor="endChimes1" className="form-label">Конец</label>
            </div>
            <input className="form-control"
                   type="number"
                   id="endChimes2"
                   min={0}
                   max={23}
                   onChange={event => {
                     event.preventDefault();
                     SetSecondEndChimes(Number(event.target.value))
                   }}
                   value={SecondEndChimes}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <div className="input-group-text">
              <label htmlFor="chimesMode" className="form-label">Режим боя</label>
            </div>
            <select className="form-control form-select"
                    id="chimesMode"
                    onChange={event => {
                      SetChimesMode(Number(event.target.value))
                    }}
                    value={ChimesMode}>
              {
                chimesModeList.map((x, index) => <option key={index} value={x.value}>{x.text}</option>)
              }
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <div className="input-group-text">
              <label htmlFor="chimesScheme" className="form-label">Схема</label>
            </div>
            <select className="form-control form-select"
                    id="chimesScheme"
                    onChange={event => {
                      SetChimesScheme(Number(event.target.value))
                    }}
                    value={ChimesScheme}>
              {
                [...Array(10).keys()].map((x, index) => <option key={index} value={x}>{x}</option>)
              }
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <div className="form-check">
              <input className="form-check-input"
                     id={"ChristmasChimes"}
                     type={"checkbox"}
                     value={ChristmasChimes}
                     onChange={event => {
                       SetChristmasChimes(!!Number(event.target.value))
                     }}
              />
              <label className="form-check-label" htmlFor="ChristmasChimes">
                Новогодний бой
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <label htmlFor="christmasSoundLevel" className="form-label">Громкость</label>
            <input type="range"
                   className="form-range"
                   min="0"
                   max="30"
                   id="christmasSoundLevel"
                   value={ChimesSoundLevel}
                   onChange={event => {
                     SetChimesSoundLevel(Number(event.target.value))
                   }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <button className="btn btn-outline-secondary" type="button" onClick={readCommand} disabled={loading}>
              {spin}
              Получить
            </button>
            <button className="btn btn-primary" type="button" onClick={writeChimesCommand} disabled={loading}>
              {spin}
              Запись боя
            </button>
            <button className="btn btn-primary" type="button" onClick={writeChimesTimeCommand} disabled={loading}>
              {spin}
              Запись режима и схемы
            </button>
            <button className="btn btn-primary" type="button" onClick={writeChimesVolumeCommand} disabled={loading}>
              {spin}
              Запись громкости
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
              {spin}
              Тест боя
            </button>
          </div>
          <div className="input-group">
            <button className="btn btn-primary" type="button" onClick={melodyCommand} disabled={loading}>
              {spin}
              Тест проигрыша
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ChimesControl;