import React, {Fragment} from "react";
import TimeControl from "./TimeControl";

const TimeInChannelsControl = ({channel, hours, minutes, onTimeChanged, onRequest}) => {
  return (
    <Fragment>
      <div className={"row"}>
        <div className={"input-group"}>
          <TimeControl key={`TimeInChannelsControl${channel}`}
                       channelNumber={channel}
                       hour={hours}
                       minute={minutes}
                       maxHoursValue={11}
                       onTimeChanged={(hours, minutes) => {
                         onTimeChanged(channel, hours, minutes)
                       }}
          />
          {
            typeof (onRequest) === 'function'
              ? (
                <button className="btn btn-primary" type="button"
                        onClick={() => {
                          onRequest(channel)
                        }}>
                  Записать
                </button>
              )
              : null
          }

        </div>
      </div>
    </Fragment>
  )
}

export default TimeInChannelsControl;