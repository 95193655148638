import React, {Fragment, useState} from "react";
import {ReadBlocks, WriteBlocks} from "../Commands";
import ToggleSwitch from "./Inputs/ToggleSwitch";
import {BitArrayToNumber, NumberToBitArray} from "../Converters";

const BlocksControl = ({executeCustomCommand, channelNumber}) => {

  const [loading, SetLoading] = useState(false);
  const [enabled, SetEnabled] = useState(false);
  const [channelBlocksList, SetChannelBlocksList] = useState([
    {text: "Блокировка канала 1", enabled: false},
    {text: "Блокировка канала 2", enabled: false},
    {text: "Блокировка канала 3", enabled: false},
    {text: "Блокировка канала 4", enabled: false},
    {text: "Блокировка синхронизации", enabled: false},
    {text: "Блокировка реле по времени", enabled: false},
    {text: "Блокировка астрономического реле", enabled: false},
    {text: "Блокировка расписания", enabled: false},
  ]);

  const [melodyBlocksList, SetMelodyBlocksList] = useState([
    {text: "Блокировка новогоднего боя", enabled: false},
    {text: "Блокировка боя часов", enabled: false},
  ]);

  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {

      const channelBlocks = BitArrayToNumber(channelBlocksList.map(x => x.enabled).reverse());
      const melodyBlocks = BitArrayToNumber(melodyBlocksList.map(x => x.enabled).reverse());

      await executeCustomCommand(WriteBlocks, [channelBlocks, melodyBlocks])
    } finally {
      SetLoading(false);
    }
  }

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCustomCommand(ReadBlocks, [])

      const channelBlocks = NumberToBitArray(result.Params[0]);
      const newChannelBlocksList = channelBlocksList.map((x, i) => {
        return {text: x.text, enabled: !!channelBlocks[channelBlocksList.length - 1 - i]}
      })

      const melodyBlocks = NumberToBitArray(result.Params[1]).slice(6, 8);
      const newMelodyBlocksList = melodyBlocksList.map((x, i) => {
        return {text: x.text, enabled: !!melodyBlocks[melodyBlocksList.length - 1 - i]}
      })

      SetChannelBlocksList(newChannelBlocksList)
      SetMelodyBlocksList(newMelodyBlocksList)

      SetEnabled(true)
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <div className="row">
        <fieldset disabled={!enabled}>
          {
            channelBlocksList.map((x, i) => (
              <div key={i} className="col-md-6 col-sm-12">
                <div className="input-group mb-3">
                  <div className="input-group-text col-8">
                    <label htmlFor={`channelBlockEnabled${i}`} className="form-label">{x.text}</label>
                  </div>
                  <div className="form-control col-4">
                    <ToggleSwitch
                      id={`channelBlockEnabled${i}`}
                      small={true}
                      name={""}
                      disabled={false}
                      checked={x.enabled}
                      onChange={() => {
                        const newChannelBlocksList = [...channelBlocksList];
                        newChannelBlocksList[i].enabled = !newChannelBlocksList[i].enabled;
                        SetChannelBlocksList(newChannelBlocksList)
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          }
          {
            melodyBlocksList.map((x, i) => (
              <div key={i} className="col-md-6 col-sm-12">
                <div className="input-group mb-3">
                  <div className="input-group-text col-8">
                    <label htmlFor={`melodyBlockEnabled${i}`} className="form-label">{x.text}</label>
                  </div>
                  <div className="form-control col-4">
                    <ToggleSwitch
                      id={`melodyBlockEnabled${i}`}
                      small={true}
                      name={""}
                      disabled={false}
                      checked={x.enabled}
                      onChange={() => {
                        const newMelodyBlocksList = [...melodyBlocksList];
                        newMelodyBlocksList[i].enabled = !newMelodyBlocksList[i].enabled;
                        SetMelodyBlocksList(newMelodyBlocksList)
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          }
        </fieldset>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="input-group">
            <fieldset disabled={loading}>
              <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                Получить
              </button>
              <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
                Записать
              </button>
            </fieldset>
            <div>
              {spin}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BlocksControl;