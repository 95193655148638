import React, {Fragment} from "react";
import {TabItemProps} from "../../types/TabTypes";

const TabItem = ({label, children}: TabItemProps) => (
  <Fragment>
    <h1>{label}</h1>
    {children}
  </Fragment>
);

export default TabItem;