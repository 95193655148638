import CommonTextControl from "../CommonTextControl";
import {ReadDevicePlace, WriteDevicePlace} from "../../Commands";

const ClockMehPlaceComponent = ({executeCommand}) => {
  const getCommand = async () => {
    const result = await executeCommand(ReadDevicePlace, [])
    const decoder = new TextDecoder("windows-1251")
    let buffer = new Uint8Array(result.Params.slice(2).slice(-16).filter(x => x !== 0));

    return decoder.decode(buffer);
  }

  const setCommand = async (bytes) => {
    await executeCommand(WriteDevicePlace, bytes)
  }

  return <CommonTextControl header="ЧТЕНИЕ МЕСТА УСТАНОВКИ"
                            getTextCommand={getCommand}
                            setTextCommand={setCommand}
  />
}

export default ClockMehPlaceComponent;