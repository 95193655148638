import React, {useState} from "react";
import './TabList.scss'
import {TabListProps} from "../../types/TabTypes";

// code from https://blog.logrocket.com/how-to-build-tab-component-react/

const TabList = ({children, activeTabIndex = 0}: TabListProps) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex);
  const handleTabClick = (index: Number) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs">
      <div className={"row"}>
        <div className={"col-2"}>
          <nav className="tab-list-wrapper">
            <ul className="tab-list" role="tablist" aria-orientation="horizontal">
              {
                children.map((tab, index) => (
                  <li key={`tab-${index}`}>
                    <button
                      key={`tab-btn-${index}`}
                      onClick={() => handleTabClick(index)}
                      className={`btn ${
                        activeTab === index && "btn-primary"
                      }`}
                    >{tab.props.label}</button>
                  </li>
                ))
              }
            </ul>
          </nav>
        </div>
        <div className={"col-10"}>
          {children[activeTab]}
        </div>
      </div>
    </div>
  );
};

export default TabList;