import {useEffect, useState} from 'react'
import authService from '../services/AuthorizeService'

const AdminComponent = ({children}) => {

  const [isAdmin, setIsAdmin] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const populateAuthenticationState = async () => {
    const authenticated = await authService.isAuthenticated();
    const user = await authService.getUser();

    const isAdmin = authenticated && user && user.isAdmin
    setIsReady(true)
    setIsAdmin(isAdmin)
  }


  const authenticationChanged = async () => {
    setIsReady(false)
    setIsAdmin(false)
    await populateAuthenticationState();
  }

  useEffect(() => {
    const subscription = authService.subscribe(() => authenticationChanged());

    populateAuthenticationState();

    return () => {
      authService.unsubscribe(subscription);
    };
  })

  if (!isReady)
    return null;

  if (isAdmin)
    return children();

  return null;
}
export default AdminComponent;