import React, {Fragment, useState} from "react";
import {
  ReadWiFiConfig, ReadWiFiState, SaveWiFiParams, ScanWiFiNetwork
} from "../Commands";
import {Esp} from "../Targets";
import IpInput from "./Inputs/IpInput";

const WifiListComponent = ({executeCommand, executeEspCommand}) => {
  const [loading, SetLoading] = useState(false);
  const [enabled, SetEnabled] = useState(false);
  const [wifiNetworks, SetWifiNetworks] = useState([]);

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await readWifiState()
      SetEnabled(true)
    } finally {
      SetLoading(false);
    }
  }

  const readWifiState = async () => {
    try {
      const result = await executeCommand(Esp, ScanWiFiNetwork, [])
      SetWifiNetworks(result.wNetworks);
    } catch (e) {
      console.error(e)
    }
  }

  const handleWifiParamsChange = (e) => {
    SetWifiNetworks({
      ...wifiNetworks,
      [e.target.name]: e.target.value
    })
  }

  const handleWifiParamsManualChange = (propertyName, value) => {
    SetWifiNetworks({
      ...wifiNetworks,
      [propertyName]: value
    })
  }

  const handleWifiParamsCheckboxChange = (e) => {
    SetWifiNetworks({
      ...wifiNetworks,
      DHCP: !wifiNetworks.DHCP
    })
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;


  return (
    <Fragment>
      <div className={"row"}>
        <h3>Сети WIFI</h3>
      </div>
      <fieldset disabled={!enabled}>
        <div className={"row"}>
          <div className="col">
            <div className="input-group mb-3">
              <select className="form-control">
                <option value={-1}>Выберите сеть</option>
                {
                  wifiNetworks.map((wifiName, index) =>
                    (
                      <option key={index} value={wifiName}>{wifiName}</option>
                    )
                  )
                }
              </select>
            </div>
          </div>
        </div>
      </fieldset>
      <div className={"row"}>
        <div className="col">
          <div className="input-group">
            <fieldset>
              <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                Получить
              </button>
              {/*<button className="btn btn-outline-secondary" type="button" onClick={writeWifiConfig} disabled={loading}>*/}
              {/*  Записать*/}
              {/*</button>*/}
            </fieldset>
            <div>
              {spin}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default WifiListComponent;