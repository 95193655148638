import React, {Fragment, useState} from "react";
import {ReadShortCircuitControlState, WriteShortCircuitControlState} from "../Commands";
import {ClockStation} from "../Targets";


const ShortCircuitControl = ({executeCommand, channelNumber}) => {

  const [loading, SetLoading] = useState(false);
  const [enabled, SetEnabled] = useState(false);
  const [shortCircuitControlState, setShortCircuitControlState] = useState(false);


  const writeParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteShortCircuitControlState, [Number(shortCircuitControlState)])

    } finally {
      SetLoading(false);
    }
  }

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ClockStation, ReadShortCircuitControlState, [])

      setShortCircuitControlState(!!result.Params[0])

      SetEnabled(true)
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <div className="input-group mb-3">
        <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
          Получить
        </button>
        <button className="btn btn-outline-secondary" type="button" onClick={writeParameters} disabled={loading || !enabled}>
          Записать
        </button>
        {
          loading ? spin : null
        }
      </div>
      <fieldset disabled={!enabled}>
        <div className="form-check">
          <input type={"checkbox"}
                 className="form-check-input"
                 id={`shortCircuitControlState`}
                 checked={shortCircuitControlState ?? false}
                 name={"shortCircuitControlState"}
                 onChange={() => {
                   setShortCircuitControlState(!shortCircuitControlState);
                 }}
          />
          <label className="form-check-label" htmlFor={"shortCircuitControlState"}>
            Контроль отключен
          </label>
        </div>
      </fieldset>
    </Fragment>
  );
}

export default ShortCircuitControl;