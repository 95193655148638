import {ReadSerialNumberCommand, WriteSerialNumberCommand} from "../Commands";
import CommonTextControl from "./CommonTextControl";

const ClockStationSerialControl = ({executeCustomCommand, onValueChanged, initialValue}) => {
  const getSerialNumberCommand = async () => {
    const result = await executeCustomCommand(ReadSerialNumberCommand, [])
    return result.Response;
  }

  const setSerialNumberCommand = async (bytes) => {
    await executeCustomCommand(WriteSerialNumberCommand, bytes)
  }

  return <CommonTextControl header="Серийный номер"
                            onValueChanged={onValueChanged}
                            getTextCommand={getSerialNumberCommand}
                            setTextCommand={setSerialNumberCommand}
                            initialValue={initialValue}
  />
}

export default ClockStationSerialControl;