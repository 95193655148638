import {ReadDeviceModel} from "../../Commands";
import CommonTextControl from "../CommonTextControl";

const ClockMehVersionControl = ({executeCommand}) => {
  const getVersionCommand = async () => {
    const result = await executeCommand(ReadDeviceModel, [])
    const decoder = new TextDecoder("windows-1251")
    let buffer = new Uint8Array(result.Params.slice(2).slice(-16).filter(x => x !== 0));

    return decoder.decode(buffer);
  }

  return <CommonTextControl header="Версия прошивки механизма"
                            getTextCommand={getVersionCommand}
  />
}

export default ClockMehVersionControl;