import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import authService from '../services/AuthorizeService';
import Loader from "../components/Loader";

const LogPage = () => {
  const {deviceId} = useParams();
  const navigate = useNavigate();
  const [loading, SetLoading] = useState(true);
  const [logs, setLogs] = useState([])

  useEffect(() => {
    populateDevicesData(deviceId)
  }, [deviceId]);

  const populateDevicesData = async (deviceId) => {
    const response = await fetch(`/api/logs/${deviceId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      }
    });

    if (!response.ok) {
      SetLoading(false)
      return;
    }

    const data = await response.json();
    setLogs(data.logs)
    SetLoading(false)
  }

  const render = () => {
    return (
      <Fragment>
        <div className="mb-3">
          <button className="btn btn-link" onClick={() => navigate("/devices", {replace: true})}>К списку устройств
          </button>
        </div>
        {
          loading
            ? <Loader/>
            : content()
        }
      </Fragment>
    )
  }

  const content = () => {
    return (
      <Fragment>
        {
          logs.map(x => <p>{x.insertDateTime} {x.data}</p>)
        }
      </Fragment>
    );
  }

  return render();
};

export default LogPage;