import {ReadClockSerialNumberCommand, WriteClockSerialNumberCommand} from "../Commands";
import CommonTextControl from "./CommonTextControl";

const ReadClockSerialNumberControl = ({executeCustomCommand, onValueChanged, initialValue}) => {
  const getSerialNumberCommand = async () => {
    const result = await executeCustomCommand(ReadClockSerialNumberCommand, [])
    return result.Response;
  }

  const setSerialNumberCommand = async (bytes) => {
    await executeCustomCommand(WriteClockSerialNumberCommand, bytes)
  }

  return <CommonTextControl header="Номер часовой системы"
                            getTextCommand={getSerialNumberCommand}
                            setTextCommand={setSerialNumberCommand}
                            onValueChanged={onValueChanged}
                            initialValue={initialValue}
  />
}

export default ReadClockSerialNumberControl;