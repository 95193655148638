export const ReadParameters = "ReadParametrs";
export const ReadModelCommand = "ReadModel";
export const ReadImpulse = "ReadImpulse";
export const WriteTimeInChannelCommand = "WriteTimeInChannel";
export const WriteDateTimeCommand = "WriteDateTime";
export const WriteImpulseDurationInChannel = "WriteImpulseDurationInChannel";
export const WriteChannelMode = "WriteChannelMode";
export const WriteTimeZoneCommand = "WriteTimeZone";
export const ReadRelayMode = "ReadRelayMode";
export const WriteRelayMode = "WriteRelayMode";
export const ReadRelayCommand = "ReadReleyState";
export const WriteRelayCommand = "WriteReley";
export const ReadAstronomicRelay = "ReadAstronomicReley";
export const WriteAstronomicRelay = "WriteAstronomicReley";
export const WriteCoordinates = "WriteCoordinates";
export const ReadVersionCommand = "ReadVersion";
export const ReadSerialNumberCommand = "ReadSerialN";
export const ReadClockSerialNumberCommand = "ReadCSN";
export const WriteClockSerialNumberCommand = "WriteCSN";
export const WriteSerialNumberCommand = "WriteSerialN";
export const ReadGlonasSerialNumber = "ReadGlonasSN";
export const ReadGlonas = "ReadGlonas";
export const WriteGlonasSerialNumber = "WriteGlonasSN";
export const ReadBlocks = "ReadBloks";
export const WriteBlocks = "WriteBloks";
export const ReadWarranty = "ReadWarranty";
export const WriteWarranty = "WriteWarranty";
export const ReadDemo = "ReadDemo";
export const WriteDemo = "WriteDemo";
export const GetVersion = "GetVersion";
export const UpdateToLatest = "updateToLatest";
export const Reboot = "";
export const ReadChimes = "ReadChimes";
export const WriteChimesTime = "WriteChimesTime";
export const WriteChimes = "WriteChimes";
export const WriteChimesVolume = "WriteChimesVolume";
export const ChimesTestCommand = "ChimesTest";
export const MelodyTestCommand = "MelodyTest";

export const ReadMehPlace = "ReadMehPlace";
export const WriteMehPlace = "WriteMehPlace";
export const ReadMehModel = "ReadMehModel";
export const WriteMehModel = "WriteMehModel";
export const ReadMehVersion = "ReadMehVersion";
export const ReadMehFabricDate = "ReadMehFabricDate";
export const WriteMehFabricDate = "WriteMehFabricDate";
export const ReadMehTehDate = "ReadMehTehDate";
export const WriteMehTehDate = "WriteMehTehDate";
export const ReadMehRepairDate = "ReadMehRepairDate";
export const WriteMehRepairDate = "WriteMehRepairDate";
export const ReadMehMinutesLifeTime = "ReadMehMinutesLifeTime";
export const ReadMehTehDays = "ReadMehTehDays";
export const WriteMehTehDays = "WriteMehTehDays";
export const ReadMehImpulse = "ReadMehImpulse";
export const WriteMehImpulse = "WriteMehImpulse";
export const ReadMehanicsMeteo = "ReadMehanicsMeteo";
export const ReadShortCircuitControlState = "ReadShortCircuitControlState";
export const WriteShortCircuitControlState = "WriteShortCircuitControlState";
export const ReadDuskDawnTime = "ReadDuskDawnTime";

// ESP Commands
export const ReadWiFiConfig = "ReadWiFiConfig";
export const SaveWiFiParams = "SaveWiFiParams";
export const ReadWiFiState = "ReadWiFiState";
export const ScanWiFiNetwork = "ScanWiFiNetwork";
export const ReadEthernetState = "ReadEthernetState";
export const SaveETHParams = "SaveETHParams";
export const SearchMechanics = "SearchMehanics";
export const ReadPresentDevices = "ReadPresentDevices";
export const SearchDevices = "SearchDevice485";

// Общие для устройств
export const ReadDeviceModel = "ReadDeviceModel";
export const ReadDevicePlace = "ReadDevicePlace";
export const WriteDevicePlace = "WriteDevicePlace";
export const ReadDeviceFabricDate = "ReadDeviceFabricDate";

// Диммер
export const ReadDimmerParams = "readDimmerParams";
export const EnableDimmerRelay = "enableDimmerRelay";
export const DisableDimmerRelay = "disableDimmerRelay";
export const SetChannelBrightness = "setChannelBrightness";
export const SetDimmerChannelsState = "setDimmerChanellsState";
export const ReadDimmerChannelVoltageRelay = "readDimmerChannelVoltageRelay";
export const ReadDimmerCoordinates = "readDimmerCoordinates";
export const ReadDimmerGlonasDateTime = "readDimmerGlonasDateTime";
export const WriteDeviceSerial = "writeDeviceSerial";
