import {Fragment, useState} from "react";
import {ReadWarranty, WriteWarranty} from "../Commands";
import {ByteArrayToNumber, NumberToByteArray} from "../Converters";
import AdminComponent from "./AdminComponent";

const WarrantyControl = ({executeCustomCommand}) => {

  const [loading, SetLoading] = useState(false);
  const [WarrantyTime, SetWarrantyTime] = useState(0);
  const [MaintenanceTime, SetMaintenanceTime] = useState(0);

  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    const maintenanceTimeArray = NumberToByteArray(MaintenanceTime, 2);
    const warrantyTimeArray = NumberToByteArray(WarrantyTime, 2);

    try {
      await executeCustomCommand(WriteWarranty, [...maintenanceTimeArray, ...warrantyTimeArray])
    } finally {
      SetLoading(false);
    }
  }

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCustomCommand(ReadWarranty, [])

      const maintenanceTime = ByteArrayToNumber(result.Params.slice(0, 2));
      const warrantyTime = ByteArrayToNumber(result.Params.slice(2, 4));

      SetMaintenanceTime(maintenanceTime)
      SetWarrantyTime(warrantyTime)

    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <p>ЧТЕНИЯ ВРЕМЕНИ ДО ТО И ГАРАНТИИ</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="warrantyTime" className="form-label">Дней до ТО</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="warrantyTime"
                     min="0"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetMaintenanceTime(Number(event.target.value))
                     }}
                     value={MaintenanceTime}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="warrantyTime" className="form-label">Дней до окончания гарантии</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="warrantyTime"
                     min="0"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetWarrantyTime(Number(event.target.value))
                     }}
                     value={WarrantyTime}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <fieldset disabled={loading}>
                <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                  Получить
                </button>
                <AdminComponent>
                  {() => (
                    <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
                      Записать
                    </button>
                  )}
                </AdminComponent>
              </fieldset>
              <div>
                {spin}
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default WarrantyControl;