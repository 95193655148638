import {ReadVersionCommand} from "../Commands";
import CommonTextControl from "./CommonTextControl";

const VersionControl = ({executeCustomCommand, onValueChanged, initialValue}) => {
  const getVersionCommand = async () => {
    const result = await executeCustomCommand(ReadVersionCommand, [])
    return result.Response;
  }

  return <CommonTextControl header="Версия прошивки"
                            getTextCommand={getVersionCommand}
                            initialValue={initialValue}
                            onValueChanged={onValueChanged}
  />
}

export default VersionControl;