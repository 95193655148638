import React, {Fragment} from "react";
import TimeInput from "./Inputs/TimeInput";

const TimeControl = ({hour, minute, onTimeChanged, maxHoursValue = 23}) => {

  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <TimeInput
            hours={hour}
            minutes={minute}
            maxHoursValue={maxHoursValue}
            onTimeChanged={({hours, minutes}) => {
              onTimeChanged(Number(hours), Number(minutes));
            }}/>
        </div>
      </div>
    </Fragment>
  )
}

export default TimeControl;