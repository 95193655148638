import React, {Fragment, useState} from "react";
import {
  ReadDuskDawnTime,
} from "../Commands";
import {ClockStation} from "../Targets";
import TimeInput from "./Inputs/TimeInput";

const DuskDawnTimeControl = ({executeCommand, channels = 0}) => {
  const [loading, setLoading] = useState(false);
  const [dawnTime, setDawnTime] = useState({hours: 0, minutes: 0});
  const [duskTime, setDuskTime] = useState({hours: 0, minutes: 0});

  const readParameters = async () => {
    setLoading(true);

    try {
      const result = await executeCommand(ClockStation, ReadDuskDawnTime, [])
      
      setDuskTime({hours: result.Params[0], minutes: result.Params[1]})
      setDawnTime({hours: result.Params[2], minutes: result.Params[3]})

    } finally {
      setLoading(false);
    }
  }

  const writeImpulseParameters = async (e) => {
    e.preventDefault();

    // setLoading(true);
    //
    // try {
    //   const enabledList = impulseDataList.map(x => x.enabled);
    //   const syncList = impulseDataList.map(x => x.sync);
    //   const unionData = [...enabledList, ...syncList].reverse();
    //
    //   const flag = BitArrayToNumber(unionData);
    //
    //   await executeCommand(ClockStation, WriteImpulseDurationInChannel, impulseDataList.map(x => x.impulse))
    //   await executeCommand(ClockStation, WriteChannelMode, [mode, flag])
    // } finally {
    //   setLoading(false);
    // }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <div className="row">
        <h4>Чтение данных восхода и заката</h4>
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <div className="input-group mb-3">
            <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
              Получить
              {
                loading ? spin : null
              }
            </button>
          </div>
        </div>
      </div>
      <div className={"row"}>
        <p>Восход</p>
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <TimeInput
            hours={duskTime.hours}
            minutes={duskTime.minutes}
            maxHoursValue={23}
            onTimeChanged={({hours, minutes}) => {
              setDuskTime({hours: Number(hours), minutes: Number(minutes)})
            }}/>
        </div>
      </div>
      <div className={"row"}>
        <p>Закат</p>
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <TimeInput
            hours={dawnTime.hours}
            minutes={dawnTime.minutes}
            maxHoursValue={23}
            onTimeChanged={({hours, minutes}) => {
              setDawnTime({hours: Number(hours), minutes: Number(minutes)})
            }}/>
        </div>
      </div>
    </Fragment>
  )
}

export default DuskDawnTimeControl;