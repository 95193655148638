import React, {Fragment, useEffect, useState} from "react";
import {
  DisableDimmerRelay,
  EnableDimmerRelay,
  ReadDimmerParams,
  SetChannelBrightness,
  SetDimmerChannelsState
} from "../../Commands";
import {BitArrayToNumber, NumberToBitArray} from "../../Converters";
import CommonTextControl from "../CommonTextControl";
import authService from "../../services/AuthorizeService";
import DimmerSerialControl from "../DimmerSerialControl";

const DimmerComponent = ({executeCommand, deviceId}) => {

  const seedInitialData = () => ({
    channels: [1, 2, 3, 4, 5, 6].map(channel => ({
      channel: channel,
      enabled: false,
      brightness: 0,
      description: ""
    })),
    enabled: false,
    description: ""
  })

  const [loading, setLoading] = useState(false);
  const [dimmerState, setDimmerState] = useState(seedInitialData());

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`/api/devices/${deviceId}/dimmer`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
        }
      });

      if (!response.ok) {
        return;
      }

      setLoading(false);

      const device = await response.json();
      console.log(device)

      let newDimmerData = structuredClone(dimmerState);
      newDimmerData.channels.map(x => x.description = device.channels.find(d => d.channel === x.channel).description)
      setDimmerState(newDimmerData)
    }

    getData()

  }, [deviceId]);

  const updateDimmerData = async () => {

    const requestData = {
      channels: dimmerState.channels.map(x => ({channel: x.channel, description: x.description}))
    };

    const body = JSON.stringify({
      Data: JSON.stringify(requestData)
    })

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    try {
      const response = await fetch(`/api/devices/${deviceId}/dimmer`, requestOptions);
      const result = await response.json();

      console.log(result)

      if (!response.ok) {
        return;
      }

      setLoading(false);

      const device = await response.json();
      console.log(device)


    } catch (e) {
      console.error(e)
    }
  }

  const readCommand = async (e) => {
    e.preventDefault();

    const startBrightnessIndex = 4;
    const channelsEnableIndex = 10;
    const dimmerEnabledIndex = 11;

    setLoading(true);

    try {
      const result = await executeCommand(ReadDimmerParams, [])

      const params = result.Params;

      const channelsEnableFlags = NumberToBitArray(result.Params[channelsEnableIndex]).reverse();
      const channelsBrightness = params.slice(startBrightnessIndex, channelsEnableIndex);
      const dimmerEnableState = !!params[dimmerEnabledIndex]

      let dimmerChannelsData = []

      for (let channel = 0; channel < 6; channel++) {
        dimmerChannelsData.push({
          enabled: channelsEnableFlags[channel],
          brightness: channelsBrightness[channel]
        })
      }

      const newDimmerState = {
        channels: dimmerChannelsData,
        enabled: dimmerEnableState
      }

      setDimmerState(newDimmerState)
    } finally {
      setLoading(false);
    }
  }

  const writeCommand = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      await executeCommand(SetChannelBrightness, dimmerState.channels.map(x => x.brightness))

      const flags = BitArrayToNumber(dimmerState.channels.map(x => x.enabled).reverse())

      await executeCommand(SetDimmerChannelsState, [flags])
    } finally {
      setLoading(false);
    }
  }

  const changeRelayState = async (enabled) => {
    setLoading(true);

    try {
      const result = await executeCommand(enabled ? EnableDimmerRelay : DisableDimmerRelay, [])

      let newDimmerState = structuredClone(dimmerState)
      newDimmerState.enabled = enabled;
      setDimmerState(newDimmerState)
    } finally {
      setLoading(false);
    }
  }

  const renderData = (data) => {
    if (data === null)
      return;

    return (
      <Fragment>
        <div className="input-group mb-3">
          <button className="btn btn-success" type="button" onClick={() => changeRelayState(true)} disabled={loading}>
            Включить
          </button>
          <button className="btn btn-danger" type="button" onClick={() => changeRelayState(false)} disabled={loading}>
            Выключить
          </button>
        </div>
        <div className="input-group mb-3">
          <div className="form-check">
            <input type={"checkbox"}
                   className="form-check-input"
                   id={"dimmerState"}
                   checked={data.enabled}
                   name={"dimmerState"}
              // onChange={() => {
              //   let newData = structuredClone(dimmerState)
              //   newData.enabled = !newData.enabled;
              //   setDimmerState(newData)
              // }}
            />
            <label className="form-check-label" htmlFor={"dimmerState"}>
              Включен
            </label>
          </div>
        </div>
        {
          data.channels.map((x, index) => (
            <div>
              <div className="form-check">
                <input type={"checkbox"}
                       className="form-check-input"
                       id={`dimmerChannelState${index}`}
                       checked={x.enabled}
                       name={"dimmerState"}
                       onChange={() => {
                         let newData = structuredClone(dimmerState)
                         newData.channels[index].enabled = !newData.channels[index].enabled
                         setDimmerState(newData)
                       }}
                />
                <label className="form-check-label" htmlFor={`dimmerChannelState${index}`}>
                  {index + 1} Канал включен
                </label>
              </div>
              <div className="input-group mb-3">
                <label htmlFor={`dimmerChannelBrightness${index}`} className="form-label">Яркость канала {index + 1}</label>
                <input type="range"
                       className="form-range"
                       min="0"
                       max="255"
                       id={`dimmerChannelBrightness${index}`}
                       value={x.brightness}
                       onChange={event => {
                         let newData = structuredClone(dimmerState)
                         newData.channels[index].brightness = Number(event.target.value)
                         setDimmerState(newData)
                       }}
                />
              </div>
              <CommonTextControl label={"Место установки"}
                                 initialValue={x.description}
                                 maxLength={16}
                                 onValueChanged={(text) => {
                                   let newData = structuredClone(dimmerState)
                                   newData.channels[index].description = text
                                   setDimmerState(newData)
                                 }}
              />
            </div>

          ))
        }
        <div className="input-group mb-3">
          <button className="btn btn-outline-secondary" type="button" onClick={writeCommand} disabled={loading}>
            Записать
          </button>
        </div>
        <DimmerSerialControl executeCommand={executeCommand} deviceId={deviceId}/>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="input-group mb-3">
        <button className="btn btn-outline-secondary" type="button" onClick={readCommand} disabled={loading}>
          Получить параметры
        </button>
      </div>
      {
        renderData(dimmerState)
      }
      <div className="input-group mb-3">
        <button className="btn btn-success" type="button" onClick={() => updateDimmerData()} disabled={loading}>
          Записать место установки
        </button>
      </div>
    </Fragment>
  );
}

export default DimmerComponent;