import {Fragment, useState} from "react";
import {
  ReadMehImpulse,
  WriteMehImpulse
} from "../../Commands";
import {ByteArrayToNumber, NumberToByteArray} from "../../Converters";

const ClockMehLifeImpulseComponent = ({executeCommand}) => {

  const [loading, SetLoading] = useState(false);
  const [Impulse, SetImpulse] = useState(undefined);

  const getCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ReadMehImpulse, [])
      SetImpulse(ByteArrayToNumber(result.Params.slice(2)))
    } finally {
      SetLoading(false);
    }
  }

  const setCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(WriteMehImpulse, NumberToByteArray(Impulse))
    } finally {
      SetLoading(false);
    }
  }

  return (
    <Fragment>
      <p>ЧТЕНИЕ ФАКТИЧЕСКОЙ ШИРИНЫ ИМПУЛЬСА</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="mehRealImpulse" className="form-label">Фактическая ширина импульса</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="mehRealImpulse"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetImpulse(Number(event.target.value))
                     }}
                     value={Impulse}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-outline-secondary" type="button" onClick={getCommand} disabled={loading}>
                Получить
              </button>
              <button className="btn btn-primary" type="button" onClick={setCommand} disabled={loading}>
                {/*{spin}*/}
                Установить
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default ClockMehLifeImpulseComponent;