import {ReadPresentDevices, SearchDevices} from "../Commands";
import React, {Fragment, useState} from "react";
import ClockComponent from "./Clock/ClockComponent";
import DimmerComponent from "./Dimmer/DimmerComponent";
import {ClockMehanics, ClockMehanicsN, Dimmer, Esp} from "../Targets";
import {Spinner} from "reactstrap";
import AdminComponent from "./AdminComponent";

const ExternalDevices = ({executeCommand, executeDeviceCommand, deviceId}) => {

  const defaultDeviceType = -1;

  const [loading, SetLoading] = useState(false);
  const [deviceIndex, setDeviceIndex] = useState(-1);
  const [deviceAddress, setDeviceAddress] = useState(-1);
  const [deviceType, setDeviceType] = useState(defaultDeviceType);
  const [deviceAddresses, setDeviceAddresses] = useState([]);

  const readDevicesAddresses = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(Esp, ReadPresentDevices, [])

      setDevicesResult(result)
    } finally {
      SetLoading(false)
    }
  }

  const searchDevicesAddresses = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(Esp, SearchDevices, [])

      console.log(result)

      setDevicesResult(result)
    } finally {
      SetLoading(false)
    }
  }

  const setDevicesResult = (result) => {
    const dimmerKeyName = "Dimmer";
    const clocksKeyName = "ClockMehanics";

    const dimmers = (result.presentDevices[dimmerKeyName] ?? []).map(x => ({Adr: x.Adr, devCode: x.devCode}));
    const clocks = (result.presentDevices[clocksKeyName] ?? []).map(x => ({Adr: x.Adr, devCode: x.devCode}));

    const totalResult = [
      {
        text: "",
        address: -1,
        type: -1
      },
      ...dimmers.map(x => ({
        text: `Диммер | Адрес: ${x.Adr} | ${String.fromCharCode(x.devCode)}`,
        address: x.Adr,
        type: x.devCode
      })),
      ...clocks.map(x => ({
        text: `Часовой механизм | Адрес: ${x.Adr} | ${String.fromCharCode(x.devCode)}`,
        address: x.Adr,
        type: x.devCode
      })),
    ]

    setDeviceAddresses(totalResult)
  }

  const executeDeviceCommandInternal = async (command: Text, parameters: Array<number>) => {
    return await executeDeviceCommand(deviceAddress, deviceType, command, parameters)
  }

  const renderCurrentDevice = () => {
    if (deviceType === defaultDeviceType)
      return;

    if (deviceType === Dimmer) {
      return (
        <Fragment>
          <h2>Диммер</h2>
          <DimmerComponent executeCommand={executeDeviceCommandInternal} deviceId={deviceId}/>
        </Fragment>
      )
    }
    if (deviceType === ClockMehanics || deviceType === ClockMehanicsN) {
      return (
        <Fragment>
          <h2>Часовой механизм</h2>
          <ClockComponent executeCommand={executeDeviceCommandInternal}/>
        </Fragment>
      )
    }
  }

  return (
    <Fragment>
      <div className="input-group mb-3">
        <div className="input-group-text">
          <label htmlFor="devicesAddresses" className="form-label">Адрес устройства</label>
        </div>
        <select className="form-control form-select"
                id="devicesAddresses"
                onChange={event => {
                  const index = Number(event.target.value);

                  setDeviceIndex(index)

                  setDeviceAddress(deviceAddresses[index].address)
                  setDeviceType(deviceAddresses[index].type)
                }}
                value={deviceIndex}>
          {
            deviceAddresses.map((x, index) => <option key={index} value={index}>{x.text}</option>)
          }
        </select>
        {
          loading ?
            (
              <span className={"btn"}>
                <Spinner/>
              </span>
            ) : null
        }
        <button className="btn btn-outline-secondary" type="button" onClick={readDevicesAddresses} disabled={loading}>
          Получить адреса
        </button>
        <AdminComponent>
          {
            () => (
              <button className="btn btn-outline-danger" type="button" onClick={searchDevicesAddresses} disabled={loading}>
                Поиск
              </button>
            )
          }
        </AdminComponent>
      </div>
      {
        renderCurrentDevice()
      }
    </Fragment>
  )
}

export default ExternalDevices;