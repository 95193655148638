import {Fragment, useState} from "react";
import {ReadDemo, WriteDemo} from "../Commands";
import {ByteArrayToNumber, NumberToByteArray} from "../Converters";
import {ClockStation} from "../Targets";

const DemoControl = ({executeCommand}) => {

  const [loading, SetLoading] = useState(false);
  const [DemoTime, SetDemoTime] = useState(0);
  const [PasswordInputCount, SetPasswordInputCount] = useState(0);
  const [Password, SetPassword] = useState("");

  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    const demoTimeArray = NumberToByteArray(PasswordInputCount, 2);
    const passwordArray = Password.match(/.{2}/g).map(x => Number(x));

    try {
      await executeCommand(ClockStation, WriteDemo, [...demoTimeArray, ...passwordArray, PasswordInputCount])
    } finally {
      SetLoading(false);
    }
  }

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ClockStation, ReadDemo, [])

      const demoTime = ByteArrayToNumber(result.Params.slice(0, 2));
      const passwordInputCount = result.Params[4];

      SetDemoTime(demoTime)
      SetPassword(result.Params.slice(2, 4).map(x => x === 0 ? "00" : x).join(''))
      SetPasswordInputCount(passwordInputCount)
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <p>Демо режим</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="demoTime" className="form-label">Дней демо режима</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="demoTime"
                     min="0"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetDemoTime(Number(event.target.value))
                     }}
                     value={DemoTime}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="demoPassword" className="form-label">Пароль</label>
              </div>
              <input className="form-control"
                     type="text"
                     id="demoPassword"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       if ((event.target.value === "" || /^\d+$/.test(event.target.value)) && event.target.value.length <= 4) {
                         SetPassword(event.target.value)
                       }
                     }}
                     value={Password}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="demoPasswordInputCount" className="form-label">Число попыток ввода пароля</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="demoPasswordInputCount"
                     min="0"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetPasswordInputCount(Number(event.target.value))
                     }}
                     value={PasswordInputCount}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <fieldset disabled={loading}>
                <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                  Получить
                </button>
                <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
                  Записать
                </button>
              </fieldset>
              <div>
                {spin}
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default DemoControl;