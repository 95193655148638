import {ReadMehMinutesLifeTime} from "../../Commands";
import CommonTextControl from "../CommonTextControl";
import {ByteArrayToNumber} from "../../Converters";

const ClockMehMinutesLifeTimeControl = ({executeCommand}) => {
  const getCommand = async () => {
    const result = await executeCommand(ReadMehMinutesLifeTime, [])
    return ByteArrayToNumber(result.Params.slice(2));
  }

  return <CommonTextControl header="КОЛИЧЕСТВО КРУГОВ ЧАСОВОЙ СТРЕЛКИ"
                            getTextCommand={getCommand}
  />
}

export default ClockMehMinutesLifeTimeControl;