import {ReadModelCommand} from "../Commands";
import CommonTextControl from "./CommonTextControl";

const ModelControl = ({executeCustomCommand, initialValue, onValueChanged}) => {
  const getSerialNumberCommand = async () => {
    const result = await executeCustomCommand(ReadModelCommand, [])
    return result.Response;
  }

  return <CommonTextControl header="Модель"
                            getTextCommand={getSerialNumberCommand}
                            initialValue={initialValue}
                            onValueChanged={onValueChanged}
  />
}

export default ModelControl;