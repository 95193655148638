import React, {Component} from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <h1>Big Clock</h1>
                <p>Управление часами</p>
            </div>
        );
    }
}
