import React, {Fragment, useState} from "react";
import {
  ReadWiFiConfig, ReadWiFiState, SaveWiFiParams
} from "../Commands";
import {Esp} from "../Targets";
import IpInput from "./Inputs/IpInput";

const WifiComponent = ({executeCommand, executeEspCommand}) => {
  const [loading, SetLoading] = useState(false);
  const [enabled, SetEnabled] = useState(false);
  const [wifiState, SetWifiState] = useState(null);
  const [wifiParams, SetWifiParams] = useState(null);

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await readWifiState()
      await readWifiConfig()
      SetEnabled(true)
    } finally {
      SetLoading(false);
    }
  }

  const readWifiState = async () => {
    try {
      const result = await executeCommand(Esp, ReadWiFiState, [])

      SetWifiState(
        {
          SSID: result.SSID,
          IP: result.wIP,
          RSSI: result.wRSSI,
          MAC: result.wMAC
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  const readWifiConfig = async () => {
    try {
      const result = await executeCommand(Esp, ReadWiFiConfig, [])
      SetWifiParams(
        {
          SSID: result.SSID,
          PASS: result.WPASS,
          DHCP: result.wDHCP,
          ip: result.wip,
          mask: result.wmask,
          gw: result.wgw,
          dns: result.wdns
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  const writeWifiConfig = async () => {
    try {
      const payload = {
        SSID: wifiParams.SSID,
        WPASS: wifiParams.PASS,
        wDHCP: wifiParams.DHCP,
        wip: wifiParams.ip,
        wmask: wifiParams.mask,
        wgw: wifiParams.gw,
        wdns: wifiParams.dns
      }
      const result = await executeEspCommand(Esp, SaveWiFiParams, payload)
    } catch (e) {
      console.error(e)
    }
  }

  const handleWifiParamsChange = (e) => {
    SetWifiParams({
      ...wifiParams,
      [e.target.name]: e.target.value
    })
  }

  const handleWifiParamsManualChange = (propertyName, value) => {
    SetWifiParams({
      ...wifiParams,
      [propertyName]: value
    })
  }

  const handleWifiParamsCheckboxChange = (e) => {
    SetWifiParams({
      ...wifiParams,
      DHCP: !wifiParams.DHCP
    })
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;


  const notDhcpFields = () => {
    return (
      <Fragment>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"IP"} octets={wifiParams?.ip} onValueChanged={(e) => {
              handleWifiParamsManualChange("ip", e.octets)
            }}/>
          </div>
        </div>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"Маска"} octets={wifiParams?.mask} onValueChanged={(e) => {
              handleWifiParamsManualChange("mask", e.octets)
            }}/>
          </div>
        </div>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"Шлюз"} octets={wifiParams?.gw} onValueChanged={(e) => {
              handleWifiParamsManualChange("gw", e.octets)
            }}/>
          </div>
        </div>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"DNS"} octets={wifiParams?.dns} onValueChanged={(e) => {
              handleWifiParamsManualChange("dns", e.octets)
            }}/>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={"row"}>
        <h3>Состояние</h3>
      </div>
      <fieldset disabled={!enabled}>
        <div className={"row"}>
          <div className="col">
            <div className="input-group mb-3">
              <div className="input-group-text">
                <label htmlFor="wSSID" className="form-label">SSID</label>
              </div>
              <input className={"form-control"} id={"wSSID"} readOnly={true} value={wifiState?.SSID}/>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <label htmlFor="wIP" className="form-label">IP</label>
              </div>
              <input className={"form-control"} id={"wIP"} readOnly={true} value={wifiState?.IP}/>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <label htmlFor="wMAC" className="form-label">MAC</label>
              </div>
              <input className={"form-control"} id={"wMAC"} readOnly={true} value={wifiState?.MAC}/>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <label htmlFor="wRSSI" className="form-label">RSSI</label>
              </div>
              <input className={"form-control"} id={"wRSSI"} readOnly={true} value={wifiState?.RSSI}/>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <h3>Параметры</h3>
        </div>
        <div className={"row"}>
          <div className="col">
            <div className="input-group mb-3">
              <div className="input-group-text">
                <label htmlFor="wParamsSSID" className="form-label">SSID</label>
              </div>
              <input className={"form-control"} id={"wParamsSSID"} value={wifiParams?.SSID}
                     name={"SSID"}
                     onChange={handleWifiParamsChange}/>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <label htmlFor="wParamsPassword" className="form-label">Password</label>
              </div>
              <input className={"form-control"} id={"wParamsPassword"} value={wifiParams?.PASS}
                     name={"PASS"}
                     onChange={handleWifiParamsChange}/>
            </div>
            <div className="input-group mb-3">
              <div className="form-check">
                <input type={"checkbox"}
                       className="form-check-input"
                       id={"wParamsDHCP"}
                       checked={wifiParams?.DHCP}
                       name={"DHCP"}
                       onChange={handleWifiParamsCheckboxChange}
                />
                <label className="form-check-label" htmlFor={"wParamsDHCP"}>
                  DHCP
                </label>
              </div>
            </div>
          </div>
        </div>
        {
          wifiParams?.DHCP === false
            ? notDhcpFields()
            : null
        }
      </fieldset>
      <div className={"row"}>
        <div className="col">
          <div className="input-group">
            <fieldset>
              <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                Получить
              </button>
              {/*<button className="btn btn-outline-secondary" type="button" onClick={writeWifiConfig} disabled={loading}>*/}
              {/*  Записать*/}
              {/*</button>*/}
            </fieldset>
            <div>
              {spin}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default WifiComponent;