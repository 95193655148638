import {Fragment, useState} from "react";
import {WriteTimeZoneCommand} from "../Commands";

const TimeZoneControl = ({executeCustomCommand}) => {

  const [loading, SetLoading] = useState(false);
  const [timeZone, SetTimeZone] = useState(3);
  const [summerTime, SetSummerTime] = useState(false);


  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCustomCommand(WriteTimeZoneCommand, [timeZone, summerTime])
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <p>Установка ЧАСОВОГО ПОЯСА И РАЗРЕШЕНИЯ ПЕРЕХОДА ЗИМА/ЛЕТО</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockTimeZone" className="form-label">Часовой пояс</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockTimeZone"
                     min="-12"
                     max="12"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetTimeZone(Number(event.target.value))
                     }}
                     value={timeZone}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="clockSummerTime"
                       value={summerTime}
                       onChange={event => {
                         SetSummerTime(event.target.value)
                       }}
                />
                <label className="form-check-label" htmlFor="clockSummerTime">
                  Автоматический переход зима/лето
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
                {spin}
                Установить
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default TimeZoneControl;