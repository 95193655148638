import React, {Fragment, useEffect, useState} from "react";
import {ReadAstronomicRelay, ReadRelayCommand, WriteAstronomicRelay, WriteCoordinates, WriteRelayCommand} from "../Commands";
import TimeInput from "./Inputs/TimeInput";
import {ClockStation} from "../Targets";

const RelayControl = ({executeCommand, channel, relayMode, SetRelayMode}) => {
  const channelDisabled = 0;
  const channelOff = 1;
  const channelOn = 2;
  const channelTimeRelay = 3;
  const channelAstronomicRelay = 4;

  const [loading, SetLoading] = useState(false);
  const [onHour, SetOnHour] = useState(0);
  const [onMinute, SetOnMinute] = useState(0);
  const [offHour, SetOffHour] = useState(0);
  const [offMinute, SetOffMinute] = useState(0);
  const [glonassRelayData, setGlonassRelayData] = useState(null);

  const states = [
    {text: "Реле отключено (работает по расписанию, если есть)", state: "channelDisabled", id: channelDisabled},
    {text: "Реле всегда включено", state: "channelOff", id: channelOff},
    {text: "Реле всегда выключено", state: "channelOn", id: channelOn},
    {text: "Реле подключено к «Реле времени»", state: "channelTimeRelay", id: channelTimeRelay},
    {text: "Реле подключено к «Астрономическому реле»", state: "channelAstronomicRelay", id: channelAstronomicRelay},
  ];

  useEffect(() => {
    if (relayMode === channelAstronomicRelay)
      readGlonassRelayCommand()
    if (relayMode === channelTimeRelay)
      readTimeRelayCommand()
  }, [relayMode]);

  const handleChange = (event) => {
    SetRelayMode(Number(event.target.value))
  }

  const readGlonassRelayCommand = async () => {
    SetLoading(true);

    // 1. Один байт 0. 
    // 2. Один байт источника координат. Равен 0 – координаты от ГЛОНАСС приёмника, не равен 0 – координаты от ручного ввода. 
    // 3. Один байт направления широты: ‘N’ север / ‘S’ юг. 
    // 4. Семь байт широты в формате "ГГГгггг": ГГГ – градусы, гггг – десятитысячные доли градуса. 
    // 5. Один байт направления долготы: 'E' восток / 'W' запад. 
    // 6. Семь байт долготы в формате "ГГГгггг": ГГГ – градусы, гггг – десятитысячные доли градуса. 
    // 7. Один байт 0.

    try {
      const result = await executeCommand(ClockStation, ReadAstronomicRelay, [])
      const params = result.Params;
      const utf8decoder = new TextDecoder()
      let buffer = new Uint8Array(result.Params.slice(2, 18));
      const text = utf8decoder.decode(buffer);

      const newGlonassRelayData = {
        isManual: !!params[1],
        latitudeDirection: text[0],
        latitude: text.slice(1, 8),
        longitudeDirection: text[8],
        longitude: text.slice(9, 16),
      }

      setGlonassRelayData(newGlonassRelayData)
    } catch (e) {
      console.error(e)
    } finally {
      SetLoading(false);
    }
  }

  const getCoordinatesString = (data) => {
    let buffer = "";

    buffer += data.latitudeDirection === "N" ? "+" : "-";
    buffer += data.latitude.padStart(7, '0');
    buffer += data.longitudeDirection === "E" ? "+" : "-";
    buffer += data.longitude.padStart(7, '0');

    return buffer;
  }

  const writeGlonassRelayCommand = async () => {
    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteAstronomicRelay, [0, Number(glonassRelayData.isManual)])
      if (glonassRelayData.isManual) {
        const coordinates = getCoordinatesString(glonassRelayData)

        const utf8Encode = new TextEncoder();
        const bytes = utf8Encode.encode(coordinates + '\n');

        await executeCommand(ClockStation, WriteCoordinates, bytes)
      }
    } finally {
      SetLoading(false);
    }
  }

  const readTimeRelayCommand = async () => {
    SetLoading(true);

    try {
      const result = await executeCommand(ClockStation, ReadRelayCommand, [])

      SetOnHour(result.Params[1])
      SetOnMinute(result.Params[2])
      SetOffHour(result.Params[3])
      SetOffMinute(result.Params[4])
    } catch (e) {
      console.error(e)
    } finally {
      SetLoading(false);
    }
  }

  const writeTimeRelayCommand = async () => {
    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteRelayCommand, [0, onHour, onMinute, offHour, offMinute])
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  const displayTimeRelayBlock = () => {
    if (relayMode !== channelTimeRelay)
      return null;

    return (
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <p>Реле времени</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span>Время включения</span>
          </div>
          <div className="col">
            <TimeInput hours={onHour} minutes={onMinute} onTimeChanged={({hours, minutes}) => {
              SetOnHour(hours);
              SetOnMinute(minutes);
            }}/>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span>Время выключения</span>
          </div>
          <div className="col">
            <TimeInput hours={offHour} minutes={offMinute} onTimeChanged={({hours, minutes}) => {
              SetOffHour(hours);
              SetOffMinute(minutes);
            }}/>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-outline-secondary" type="button" onClick={readTimeRelayCommand} disabled={loading}>
                {spin}
                Получить
              </button>
              <button className="btn btn-primary" type="button" onClick={writeTimeRelayCommand} disabled={loading}>
                {spin}
                Установить
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    )
  }

  const renderAstronomicRelayCoordinates = () => {
    return (
      <Fragment>
        <div className="row">
          <fieldset disabled={glonassRelayData === null || !glonassRelayData?.isManual}>
            <div className={"input-group mb-3"}>
              <div className="input-group-text">
                <label className="form-label">Широта</label>
              </div>
              <select className="form-control"
                      onChange={event => {
                        const newGlonassRelayData = structuredClone(glonassRelayData)
                        newGlonassRelayData.latitudeDirection = event.target.value
                        setGlonassRelayData(newGlonassRelayData)
                      }}
                      value={glonassRelayData?.latitudeDirection}
              >
                <option value={"N"}>N</option>
                <option value={"S"}>S</option>
              </select>
              <input className="form-control"
                     type="text"
                     onChange={event => {
                       const newGlonassRelayData = structuredClone(glonassRelayData)
                       newGlonassRelayData.latitude = event.target.value
                       setGlonassRelayData(newGlonassRelayData)
                     }}
                     value={glonassRelayData?.latitude}
              />
            </div>
          </fieldset>
        </div>
        <div className="row">
          <fieldset disabled={glonassRelayData === null || !glonassRelayData?.isManual}>
            <div className={"input-group mb-3"}>
              <div className="input-group-text">
                <label className="form-label">Долгота</label>
              </div>
              <select className="form-control"
                      onChange={event => {
                        const newGlonassRelayData = structuredClone(glonassRelayData)
                        newGlonassRelayData.longitudeDirection = event.target.value
                        setGlonassRelayData(newGlonassRelayData)
                      }}
                      value={glonassRelayData?.longitudeDirection}
              >
                <option value={"E"}>E</option>
                <option value={"W"}>W</option>
              </select>
              <input className="form-control"
                     type="text"
                     onChange={event => {
                       const newGlonassRelayData = structuredClone(glonassRelayData)
                       newGlonassRelayData.longitude = event.target.value
                       setGlonassRelayData(newGlonassRelayData)
                     }}
                     value={glonassRelayData?.longitude}
              />
            </div>
          </fieldset>
        </div>
      </Fragment>
    )
  }

  const displayAstronomicRelayBlock = () => {
    if (relayMode !== channelAstronomicRelay)
      return null;

    return (
      <fieldset>
        <div className="row">
          <div className="input-group mb-3 col">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id={`glonassManualInput_${channel}`}
                     checked={glonassRelayData?.isManual}
                     onChange={event => {
                       const newGlonassRelayData = structuredClone(glonassRelayData)
                       newGlonassRelayData.isManual = !newGlonassRelayData.isManual
                       setGlonassRelayData(newGlonassRelayData)
                     }}
                     disabled={glonassRelayData === null}
                     readOnly={glonassRelayData === null}
              />
              <label className="form-check-label" htmlFor={`glonassManualInput_${channel}`}>
                Ручной ввод
              </label>
            </div>
          </div>
        </div>
        {
          glonassRelayData?.isManual
            ? renderAstronomicRelayCoordinates()
            : null
        }
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-outline-secondary" type="button" onClick={readGlonassRelayCommand} disabled={loading}>
                {spin}
                Получить
              </button>
              <button className="btn btn-primary" type="button" onClick={writeGlonassRelayCommand} disabled={loading}>
                {spin}
                Установить
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    )
  }

  return (
    <Fragment>
      <p>{channel + 1} канал расписания</p>
      <fieldset className={"mb-3"} disabled={loading}>
        <div className="row">
          <div className="col">
            {
              states.map((x, index) => {
                return (
                  <div className="form-check" key={index}>
                    <input className="form-check-input"
                           type="radio"
                           name={`channel${channel}`}
                           value={x.id}
                           id={`${x.state}_${x.id}_${channel}`}
                           onChange={handleChange}
                           checked={relayMode === x.id}
                    />
                    <label className="form-check-label" htmlFor={`${x.state}_${x.id}_${channel}`}>
                      {x.text}
                    </label>
                  </div>
                )
              })}
          </div>
        </div>
      </fieldset>
      {
        displayTimeRelayBlock()
      }
      {
        displayAstronomicRelayBlock()
      }
    </Fragment>
  )
}

export default RelayControl;