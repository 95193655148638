import {Fragment, useState} from "react";
import {ReadMehTehDays, ReadWarranty, WriteMehTehDays, WriteWarranty} from "../../Commands";
import {ByteArrayToNumber, NumberToByteArray} from "../../Converters";
import AdminComponent from "../AdminComponent";

const ClockWarrantyControl = ({executeCommand}) => {

  const [loading, SetLoading] = useState(false);
  const [MaintenanceDays, SetMaintenanceDays] = useState(undefined);

  const sendCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    const maintenanceDays = NumberToByteArray(MaintenanceDays, 2);

    try {
      await executeCommand(WriteMehTehDays, maintenanceDays)
    } finally {
      SetLoading(false);
    }
  }

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ReadMehTehDays, [])

      const maintenanceDays = ByteArrayToNumber(result.Params.slice(2));

      SetMaintenanceDays(maintenanceDays)

    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <p>КОЛИЧЕСТВO ДНЕЙ ДО ТО</p>
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor="clockWarrantyDays" className="form-label">Дней до ТО</label>
              </div>
              <input className="form-control"
                     type="number"
                     id="clockWarrantyDays"
                     min="0"
                     required={true}
                     onChange={event => {
                       event.preventDefault();
                       SetMaintenanceDays(Number(event.target.value))
                     }}
                     value={MaintenanceDays}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <fieldset disabled={loading}>
                <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                  Получить
                </button>
                <AdminComponent>
                  {
                    () => (
                      <button className="btn btn-primary" type="button" onClick={sendCommand} disabled={loading}>
                        Записать
                      </button>
                    )
                  }
                </AdminComponent>
              </fieldset>
              <div>
                {spin}
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default ClockWarrantyControl;